import React, {useEffect, useRef} from 'react';
import "../../style.css";
import "./WorkSection.css";
import CardMedia from '@mui/material/CardMedia';

function WorkSection(props) {
    const [open, setOpen] = React.useState(false);
    const workRow2Ref = useRef(null);
    const workSectionRef = useRef(null);
    const workDescRef = useRef(null);

    function onToggle() {
        setOpen(!open);
    }

    const handleClickOpen = () => {
        const workRow2Element = workRow2Ref.current;
        const workDescElement = workDescRef.current;
        workRow2Element.style.height = '0px';
        workDescElement.style.maxHeight = `${workDescElement.scrollHeight}px`;
        requestAnimationFrame(() => {
            workRow2Element.style.height = `${workRow2Element.scrollHeight}px`;
            workDescElement.style.maxHeight = '0px';
        });
        onToggle();
    };

    const handleClickClose = () => {
        const workRow2Element = workRow2Ref.current;
        const workDescElement = workDescRef.current;
        workRow2Element.style.height = `${workRow2Element.scrollHeight}px`;
        workDescElement.style.maxHeight = '0px';
        requestAnimationFrame(() => {
            workRow2Element.style.height = '0px';
            workDescElement.style.maxHeight = `${workDescElement.scrollHeight}px`;
        });
        onToggle();
    };

    useEffect(() => {
        const workRow2Element = workRow2Ref.current;
        const workDescElement = workDescRef.current;
        if (open) {
            const handleTransitionEnd = () => {
                workRow2Element.style.height = 'auto';
                workDescElement.style.maxHeight = '0px';
                workRow2Element.removeEventListener('transitionend', handleTransitionEnd);
            };
            workRow2Element.addEventListener('transitionend', handleTransitionEnd);
        } else {
            const handleTransitionEnd = () => {
                workRow2Element.style.height = '0px';
                workDescElement.style.maxHeight = 'fit-content';
                workRow2Element.removeEventListener('transitionend', handleTransitionEnd);
            };
            workRow2Element.addEventListener('transitionend', handleTransitionEnd);
        }
    }, [open]);

    return (
        <div ref={workSectionRef} id={props.id} onClick={open ? handleClickClose : handleClickOpen}
             className={"work-section " + props.className + (open ? " work-section-open" : "")}>
            <div className={"work-row"}>
                <div className={"flex1 flex_col work-left" + (open ? " work-left-open" : "")}>
                    <div className={"work-title flex0 flex_col" + (open ? " work-title-open" : "")}>
                        <div className={"work-title-year flex0"}>2024</div>
                        <div className={"work-title-name flex0"}>{props.title}</div>
                        <div ref={workDescRef}
                            className={"work-desc-segment flex1" + (open ? " work-desc-segment-open" : " work-desc-segment-close")}>
                            {props.desc.map((item, index) => {
                                return (
                                    <div key={index} className={"work-desc-line"}>{item}</div>
                                )
                            })}
                        </div>
                    </div>
                    <div className={"work-desc flex1 flex_row" + (open ? " work-desc-open" : "")}>
                        <div className={"work-type-text flex2 flex_col"}>
                            <div className={"flex0 flex_col work-type-block" + (open ? " work-type-block-open" : "")}>
                                {props.type.map((item, index) => {
                                    return (
                                        <div key={index} className={"work-type-line"}>{item}</div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"work-content flex_col" + (open ? " work-right-open" : " work-right-close")}>
                    <div className={"work-item flex1"}>
                        <CardMedia component="img" alt={props.title} image={props.img} className={"work-img"}/>
                    </div>
                </div>
            </div>
            <div ref={workRow2Ref} className={"work-row-2 work-row" + (open ? " work-row-2-open" : "")}>
                <div className={"flex1 flex_col work-empty"}>
                </div>
                <div className={"work-detail" + (open ? " work-right-open" : " work-right-close")}>
                    <div className={"flex0 work-detail-left flex_col work-detail-col flex_col"}>
                        <div>
                            Cycles is a meticulously crafted luminaire that integrates the tranquil rhythms of nature
                            into daily living. Drawing inspiration from natural phenomena such as tidal movements and
                            seasonal transitions, its dynamic silhouette emulates a slowed human breath, emphasizing
                            mindfulness and well-being. This interactive lamp endeavors to bridge the gap between the
                            rush of modernity and the serenity of nature, prompting users to pause and reconnect with
                            life's inherent cadence.
                        </div>
                        <div>
                            Conceptually, the design journey began with a series of thought-provoking questions
                            revolving around the essence and impact of light, leading to the creation of evocative mood
                            boards. These boards, rooted in beams of light, optical illusions, and the beauty of the
                            natural world, honed the aesthetic direction, culminating in the central theme of 'rhythm'.
                        </div>
                    </div>
                    <div className={"flex1"}></div>
                    <div className={"flex0 work-detail-right work-detail-col"}>
                        <table className={"work-detail-right-right"}>
                            <tbody>
                            <tr>
                                <th>Role</th>
                            </tr>
                            <tr>
                                <td>Designer / Developer</td>
                            </tr>
                            <tr>
                                <th>Tech Stack</th>
                            </tr>
                            <tr>
                                <td>Arduino / 3D Modelling / 3D Priting</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className={"flex1"}></div>
                </div>
            </div>
        </div>
    );
}

export default WorkSection;
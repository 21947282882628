export const WORKS = [
    {
        img: "images/tellme/tellme_1.jpg",
        title: "Te(llM)e",
        type: ["Frontend Design", "Frontend Development"],
        desc: ["We build a system to respond to online surveys automatically using artificial responses. The system is capable of simulating various personas to ensure the survey can be targeted at specific user groups."]
    },
    {
        img: "images/covid19/covid19_4.jpg",
        title: "Pandemic Dashboard",
        type: ["Frontend Design", "Frontend Development"],
        desc: ["A system to help users monitor the spread of the COVID-19 pandemic."]
    },
    {
        img: "images/acs360/acs360_7.jpg",
        title: "ACS360",
        type: ["Frontend Design", "Full-Stack Development"],
        desc: [""]
    },
    {
        img: "images/overwatch/overwatch_1.jpg",
        title: "Overwatch",
        type: ["Frontend Design", "Prototyping"],
        desc: ["A system to help users track their daily activities and provide feedback on their performance."]

    },
    {
        img: "images/ml/ml_1.jpg",
        title: "Usable Machine Learning",
        type: ["Frontend Design", "Frontend Development"],
        desc: ["A dashboard to help users monitor the performance of their machine learning models."]
    },
    {
        img: "images/cubes1.jpg",
        title: "The Tree",
        type: ["Tangible User Interface"],
        desc: ["By abandoning encoding and decoding, the design aims to invite the users to the creation of individual experience and create a direct and intuitive interaction."]
    },
    {
        img: "images/cycles.jpg",
        title: "Cycles",
        type: ["Shape-Shifting User Interface"],
        desc: ["A meticulously crafted luminaire that integrates the tranquil rhythms of nature into daily living."]
    },
];
import './Layout.css';
import React, {useEffect, useState} from "react";
import {Outlet} from "react-router-dom";
import {AppBar, Box, Toolbar} from "@mui/material";
import {HashLink} from "react-router-hash-link";
import {ReactComponent as Logo} from "./images/logo-name.svg"

function Layout() {
    const [sections, setSections] = useState([]);
    const [currentSection, setCurrentSection] = useState('home');
    const [closeToSection, setCloseToSection] = useState('');
    const logoRef = React.useRef(null);

    const updateCurrentSection = () => {
        let current = ""
        let close = "";
        sections.forEach(section => {
            const sectionTop = section.getBoundingClientRect().top;
            if (parseInt(sectionTop) <= 0) {
                current = section.id;
            } else if (parseInt(sectionTop) <= 400) {
                close = section.id;
                return;
            }
        });
        setCurrentSection(current);
        setCloseToSection(close);
    };

    const handleResize = () => {
        updateCurrentSection();
    }

    const updateNavLinks = () => {
        const header = document.querySelector('.header');
        const navLinks = document.querySelectorAll('.nav-links a');
        const logo = logoRef.current;

        navLinks.forEach(link => {
            link.classList.remove('active-section');
            link.classList.add('inactive-section');
            if (link.getAttribute('href') === "/#" + currentSection || link.getAttribute('href') === "/#" + currentSection.replace("banner-", "")) {
                link.classList.add('active-section');
                link.classList.remove('inactive-section');
            }
        });

        if (header) {
            if (currentSection === "work" || currentSection === "contact" || currentSection === "banner-work" || closeToSection === "work" || currentSection === "banner-contact") {
                header.classList.add('header-dark');
                header.classList.remove('header-light');
                logo.classList.add('header-dark');
                logo.classList.remove('header-light');
                if (currentSection === "contact") {
                    logo.classList.add('logo-last');
                } else {
                    logo.classList.remove('logo-last');
                }
            } else {
                header.classList.add('header-light');
                header.classList.remove('header-dark');
                logo.classList.add('header-light');
                logo.classList.remove('header-dark', 'logo-last');
            }
        }
    }

    useEffect(() => {
        setSections(document.querySelectorAll('.section'));
    }, []);

    useEffect(() => {
        window.addEventListener('scroll', updateCurrentSection);
        window.addEventListener('resize', handleResize);
        const hash = window.location.hash;
        if (hash) {
            const targetSection = document.querySelector(hash);
            if (targetSection) {
                targetSection.scrollIntoView({behavior: 'smooth'});
            }
        }
    }, [sections]);

    useEffect(() => {
        updateNavLinks();
    }, [currentSection, closeToSection, sections]);

    return (
        <>
            <div className={"app"}>
                <HashLink to={"/#home"}>
                    {/*<img src={logo} className={"logo"} alt={"TellMe"}/>*/}
                    <Logo ref={logoRef} className={"logo"}/>
                </HashLink>
                <Box className={"header"} sx={{display: 'flex'}}>
                    <AppBar position="static">
                        <Toolbar>
                            <div className={"header-div flex_row nav-links"}>
                                {/*<div className={"flex1 flex-col logo"}>*/}
                                {/*<HashLink to="/#home">*/}
                                {/*<div>ZIYANG ZHOU</div>*/}

                                {/*</HashLink>*/}
                                {/*</div>*/}
                                {/*<div className={"nav-links"}>*/}
                                <HashLink to="/#home" className={"active-section"}>Home</HashLink>
                                <HashLink to="/#work" className={"inactive-section"}>Work</HashLink>
                                <HashLink to="/#contact" className={"inactive-section"}>Contact</HashLink>
                                {/*</div>*/}
                            </div>
                        </Toolbar>
                    </AppBar>
                </Box>
                <Box component="main" sx={{p: 3}} className={"homepage-container"}>
                    <Outlet/>
                </Box>
            </div>
        </>
    );
}

export default Layout;
import "./App.css";
import React from 'react';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import Layout from "./Layout";
import Home from "./pages/Home/Home";

function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Layout/>}>
                    <Route index element={<Home/>}/>
                    <Route path="/" element={<Home/>}/>
                    {/*<Route path="edit_survey" element={<EditSurvey/>}/>*/}
                    {/*<Route path="my_surveys" element={<MySurveys/>}/>*/}
                </Route>
            </Routes>
        </BrowserRouter>
    );
}

export default App;
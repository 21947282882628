import React, { useState } from 'react';
import WorkSection from './WorkSection'; // Adjust the import path as necessary

function WorkSectionsContainer({ sections, className }) {
    // const [openIndex, setOpenIndex] = useState(null);
    //
    // const handleToggle = (index) => {
    //     setOpenIndex(openIndex === index ? null : index);
    // };

    return (
        <>
            {sections.map((section, index) => (
                <WorkSection
                    key={index}
                    id={"work_" + index}
                    {...section}
                    className={className}
                    // open={openIndex === index}
                    // onToggle={() => handleToggle(index)}
                />
            ))}
        </>
    );
}

export default WorkSectionsContainer;
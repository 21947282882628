import React, {useEffect, useRef} from 'react';
import "../../style.css";
import "./Home.css";
import {HashLink} from "react-router-hash-link";
import KeyboardArrowDownSharpIcon from "@mui/icons-material/KeyboardArrowDownSharp";
import {WORKS} from "../../config";
import WorkSectionsContainer from "../../components/WorkSection/WorkSectionsContainer";

function Home() {
    const workBannerRef = useRef(null);
    const contactBannerRef = useRef(null);
    const homeSectionRef = useRef(null);
    const [contact, setContact] = React.useState(false);

    const handleScroll = () => {
        // const homeSection = document.querySelector('.home');
        const homeSection = homeSectionRef.current;
        const scrollPosition = window.scrollY;
        const homeSectionHeight = homeSection.offsetHeight;
        const keyframes_ratio = [0.25, 0.5, 1];
        const home_keyframes = keyframes_ratio.map(ratio => homeSectionHeight * ratio);

        if (scrollPosition <= home_keyframes[0]) {
            homeSection.style.opacity = 1;
            const width = 100 - Math.max((scrollPosition / home_keyframes[0]), 0) * 100 + '%';
            document.documentElement.style.setProperty('--stripe-width', width);
        } else if (scrollPosition > home_keyframes[0] && scrollPosition <= home_keyframes[1]) {
            homeSection.style.opacity = 1;
            document.documentElement.style.setProperty('--stripe-width', '0%');
        } else if (scrollPosition > home_keyframes[1] && scrollPosition <= home_keyframes[2]) {
            homeSection.style.opacity = 1 - ((scrollPosition - home_keyframes[1]) / (home_keyframes[2] - home_keyframes[1])) * 1.5;
            document.documentElement.style.setProperty('--stripe-width', '0%');
        } else {
            homeSection.style.opacity = 0;
            document.documentElement.style.setProperty('--stripe-width', '0%');
        }

        if (workBannerRef) {
            const workBanner = workBannerRef.current;
            const workBannerRect = workBanner.getBoundingClientRect();
            if (workBannerRect.bottom >= 0 && workBannerRect.top <= window.innerHeight) {
                workBanner.style.left = `-${workBannerRect.bottom * 1.8}px`;
            }
        }

        if (contactBannerRef) {
            const contactBanner = contactBannerRef.current;
            const contactBannerRect = contactBanner.getBoundingClientRect();
            // console.log(contactBannerRect.bottom);
            if (contactBannerRect.bottom >= 0 && contactBannerRect.top <= window.innerHeight) {
                contactBanner.style.left = `-${contactBannerRect.bottom * 1.8}px`;
            }
        }

        const contactSection = document.querySelector('.contact');
        const contactSectionRect = contactSection.getBoundingClientRect();
        const sectionTop = contactSectionRect.top;
        if (parseInt(sectionTop) > 0) {
            setContact(false);
        } else {
            setContact(true);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        window.addEventListener('resize', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
            window.removeEventListener('resize', handleScroll);
        };
    }, []);

    return (<>
        <div ref={homeSectionRef} className="home section" id={"home"}>
            <div className="page-container home-container flex_col">
                <div className={"video_container"}>
                    <video autoPlay muted loop playsInline id="myVideo" poster={"images/bg_video_1.jpg"}>
                        <source src="bg_video_1.mp4" type="video/mp4"/>
                    </video>
                </div>
                <div className="home-role">
                    Developer / Designer / Human-Centred Problem Solver
                </div>
                <div className="home-headline page">
                    <span className="font-medium selected" data-text={"unveiling"}>unveiling</span>
                    <span className="font-medium"> the power of </span>
                    <span className="font-medium"> digital experience through cutting-edge </span>
                    <span className="font-light">technology</span>
                    <span className="font-medium"> and </span>
                    <span className="font-light">creativity</span>
                    <span className="font-medium">.</span>
                </div>
                {/*<div className={"next-page flex_col"}>*/}
                {/*    <div className={"next-page-button"}>*/}
                {/*        <HashLink to={"/#work"} className={"next-page-text"}>*/}
                {/*            <KeyboardArrowDownSharpIcon fontSize="inherit"/>*/}
                {/*        </HashLink>*/}
                {/*    </div>*/}
                {/*</div>*/}
            </div>
        </div>
        <div className={"banner section"} id={"banner-work"}>
            <HashLink to={"/#work"} className={"next-page-banner"} >
            <div ref={workBannerRef} className={"banner-content"}>
                WORK↓WORK↓WORK↓WORK↓WORK↓WORK↓WORK↓WORK↓WORK↓WORK↓WORK↓WORK↓WORK↓WORK↓WORK↓WORK↓WORK↓WORK↓WORK↓WORK↓WORK↓WORK↓WORK↓WORK↓WORK↓WORK↓WORK↓WORK↓WORK↓WORK↓WORK↓WORK↓WORK↓WORK↓
            </div>
            </HashLink>
        </div>
        <div className="work section" id={"work"}>
            <div className={"page-container work-container flex_col"}>
                <WorkSectionsContainer className={"work-item"} sections={WORKS}/>
            </div>
        </div>
        <div className={"banner section"} id={"banner-contact"}>
            <HashLink to={"/#contact"} className={"next-page-banner"} >
            <div ref={contactBannerRef} className={"banner-content"}>
                CONTACT↓CONTACT↓CONTACT↓CONTACT↓CONTACT↓CONTACT↓CONTACT↓CONTACT↓CONTACT↓CONTACT↓CONTACT↓CONTACT↓CONTACT↓CONTACT↓CONTACT↓CONTACT↓CONTACT↓CONTACT↓CONTACT↓CONTACT↓CONTACT↓CONTACT↓
            </div>
            </HashLink>
        </div>
        <div className={"contact section"} id={"contact"}>
            <div className={"page-container contact-container flex_col"}>
                <div className={"flex1 flex_col contact-quote"}>
                    <div className={"flex0 contact-quote-text"}>
                        On mighty wings the spirit of history floats through the ages, and shows—giving courage and
                        comfort, and awakening gentle thoughts—on the dark nightly background, but in gleaming
                        pictures, the thorny path of honor, which does not, like a fairy tale, end in brilliancy and
                        joy here on earth, but stretches out beyond all time, even into eternity!
                    </div>
                    <div className={"flex1 contact-quote-author"}>- Hans Christian Andersen</div>
                </div>
                <div
                    className={"contact-detail flex0 flex_row" + (contact ? " contact-detail-current" : " contact-detail-not-current")}>
                    <div className={"flex0 contact-detail-segment flex_col"}>
                        <div>
                            <div><a href={"https://github.com/zhou-ziyang/"} target={"_blank"}>Github</a></div>
                            <div><a href={"https://www.linkedin.com/in/ziyang-zhou/"} target={"_blank"}>LinkedIn</a>
                            </div>
                            <div><a href={"https://www.zhouziyang.com/"} target={"_blank"}>Photography</a></div>
                        </div>
                    </div>
                    <div className={"flex1 flex_col contact-detail-segment contact-detail-segment-center"}>
                        <div>
                            <div className={"contact-detail-head"}>Email</div>
                            <div><a href={"mailto:contact@zhouziyang.com"}>contact@zhouziyang.com</a></div>
                        </div>
                    </div>
                    <div className={"flex0 flex_col contact-detail-segment contact-detail-segment-right"}>
                        <div>
                            <div className={"flex0"}>All rights reserved.</div>
                            <div className={"flex0"}>Copyright © 2024</div>
                            {/*<div className={"flex0"}>All rights reserved.</div>*/}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>);
}

export default Home;